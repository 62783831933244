<script setup>
  import { onMounted } from 'vue';
  import { useStore } from 'vuex'
  import { initServiceWorker } from '../entrypoints/utils.js';
  import api from '../entrypoints/api.js';

  import Navbar from './Navbar.vue';
  import ConfirmDialog from 'primevue/confirmdialog';
  import ConfirmPopup from 'primevue/confirmpopup';

  const store = useStore()

  onMounted(async () => {
    setTimeout(async () => api.subscribeWebpush(store.getters.getAuthToken, store.getters.getUserParticipant?.id || null), 2000);
  })
</script>

<template>
  <ConfirmPopup group="popup" />
  <ConfirmDialog group="dialog" />
  <Toast />
  <Navbar />

  <RouterView />
</template>
