// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'


// Create vue app
import { createApp } from "vue";
import store from './store.js';
import router from './router.js';
import i18n from './i18n.js';


// Styling
import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primevue/resources/primevue.min.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/lara-dark-blue/theme.css';
// import Lara from '@/presets/lara';

// Directive
import Ripple from 'primevue/ripple';

// App components
import App from "../components/App.vue";
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';

// Create the vue app
const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(PrimeVue)
  // .use(PrimeVue, {
  //   unstyled: true,
  //   pt: Lara,
  // })
  // Services
  .use(ConfirmationService)
  .use(ToastService)

  .directive('ripple', Ripple)

  // Components
  .component('ConfirmDialog', ConfirmDialog)
  .component('ConfirmPopup', ConfirmPopup)
  .component('Toast', Toast)

  // Globals

  // Mount app
  .mount("#app");
